<template>
  <PullRefresh v-model="refreshing" @refresh="handleRefresh" :pulling-text="$t('pullRefresh.pulling')"
    :loosing-text="$t('pullRefresh.pulling')" :loading-text="$t('pullRefresh.loading')"
    :success-text="$t('pullRefresh.success')" class="list_wrap">
    <List v-model="loading" :finished="!hasMore" :finished-text="$t('Hint.notMore')" :loading-text="$t('Hint.loading')"
      @load="onload" :error.sync="error" :error-text="$t(errorMsg)">
      <div v-for="(item, index) in list" :key="item.id" :class="{'flex_list': flex, [listWrapClass || '']: true}">
        <slot name="item" :data="item" :index="index">
          {{ item }}
        </slot>
      </div>
      <template #finished>
        <div class="nodata" v-if="!list.length">
          <VanImage :src="verConfig.nulltrade || nullImg" mode="aspectFit" style="width: 100%" />
        </div>
        <div class="nomore" v-else>
          {{ $t("noMore") }}
        </div>
      </template>
    </List>
  </PullRefresh>
</template>

<script>
import { Toast, PullRefresh, List, Image as VanImage } from 'vant';
import { GET_LIST_COM } from "@tt/api";
import noDataBack from "@tt/assets/Img/null/null.png";

export default {
  components: {
    PullRefresh, List, VanImage
  },
  props: {
    url: String,
    initParams: Object,
    perPage: {
      type: Number,
      default: 10,
    },
    dataKey: {
      type: String,
      default: "lists",
    },
    nullImg: {
      type: String,
      default: noDataBack
    },
    cusLoad: Boolean,
    listWrapClass: String,
    flex: Boolean
  },
  data: () => ({
    refreshing: false,
    list: [],
    hasMore: true,
    loading: false,
    page: 0,
    params: {},
    error: false,
    errorMsg: 'listStatus.error'
  }),
  methods: {
    genParams() {
      this.params.page_size = this.perPage;
      this.params.page = this.page;
      if (this.initParams) {
        this.params = { ...this.params, ...this.initParams };
      }
    },
    setLoading() {
      this.loading = true;
    },
    clearLoading() {
      this.loading = false;
      this.refreshing = false;
    },
    onload() {
      if (!this.url) return;
      if (this.cusLoad && this.page === 0) {
        this.hasMore = false;
        this.loading = false;
        return
      }
      this.getList();
    },
    async getList() {
      this.page++;
      this.genParams();
      this.setLoading();
      try {
        const r = await GET_LIST_COM(
          this.url,
          this.params
        );
        const { currentPage, hasMorePages, lists } = r;
        this.hasMore = hasMorePages;
        if (currentPage === 1) {
          this.list = lists;
        } else {
          this.list = [...this.list, ...lists];
        }
        this.clearLoading();
      } catch (error) {
        console.error("列表数据获取失败:", {
          msg: error,
          url: this.url
        })
        this.page--;
        this.clearLoading();
        this.error = true;
        this.errorMsg = error.msg;
        Toast(this.$t(error.msg))
      }
    },
    handleRefresh(params = {}) {
      this.refreshing = true;
      this.page = 0;
      this.hasMore = true;
      this.params = { ...this.params, ...params }
      this.getList();
    }
  },
}
</script>

<style lang="less" scoped>
.nomore {
  line-height: 50px;
}

.list_wrap {
  min-height: 30rem;
}

.nodata {
  max-width: 750px;
  width: 100%;
  height: calc(100vh - calc(44rem / 32));
  background-color: #fff;
}
.flex_list{
  display: flex;
  flex-wrap: wrap;
}
</style>